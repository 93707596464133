import React from 'react'
import CardsHeader from '../components/Cards/CardsHeader'
import CardsWrapper from '../components/Cards/CardsWrapper'

function Card() {
  return (
    <div className='Card'>
        <CardsHeader showBalance={true} />

        <div className="card__container">
            <div className="title">
                Приобритение карточек
            </div>

            <div className="subtitle">
                Покупайте карточки с интересным контентом и получайте бонусы за их владение.
            </div>
        </div>

        <CardsWrapper />
    </div>
  )
}

export default Card