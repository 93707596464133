import { useEffect, useState } from 'react';
// import './App.css'

function ConnectWallet() {
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const queryObject = {};
    params.forEach((value, key) => {
      queryObject[key] = value;
    });
    setQueryParams(queryObject);
  }, []);

  const connectPhantomWallet = async () => {
    if ('solana' in window) {
      const provider = window.solana;

      if (provider.isPhantom) {
        try {
          const response = await provider.connect();
          const walletAddress  = response.publicKey;
          console.log(`Connected to Phantom! Address: ${walletAddress.toString()}`);
          fetch(
            `https://app.bdsm.money:5000/user/${queryParams.userId}/save-wallet/`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(
                {
                  walletAddress: walletAddress.toString(),
                  token: queryParams.token
                }
              )
            }
          );
        } catch (err) {
          console.error('User rejected the connection', err);
        }
      } else {
        console.log('Phantom Wallet not detected. Please install it!');
      }
    } else {
      console.log('Solana wallet not found. Please install Phantom Wallet!');
    }
  }

  return (
    <>
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h1>Connect Your Solana Wallet</h1>
        <div style={{ margin: '20px' }}>
          <button
            onClick={connectPhantomWallet}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              cursor: 'pointer',
            }}
          >
            Connect Phantom Wallet
          </button>
        </div>
      </div>
    </>
  )
}

export default ConnectWallet;
