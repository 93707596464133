import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../api';
import { showNotification } from '../ToastNotification';

const useUserStore = create(
  devtools((set, get) => ({
    user: null,
    isLoading: false,
    error: null,

    fetchUser: async (userId) => {
      set({ isLoading: true, error: null });
      try {
        const response = await api.get(`/user/${userId}`);
        set({ user: response.data });
      } catch (error) {
        showNotification('Ошибка при получении пользователя', 'error');
        set({ error: 'Ошибка при получении пользователя' });
      }
    },    

    updateUserBalance: async (userId, balance) => {
      try {
        const response = await api.post(`/user/${userId}/update-balance`, { balance });
        set({ user: { ...get().user, balance: response.data.balance } });
        showNotification('Баланс успешно обновлён', 'success');
      } catch (error) {
        showNotification('Ошибка при обновлении баланса', 'error');
        set({ error: 'Ошибка при обновлении баланса' });
      }
    },

    regenEnergy: async (userId) => {
      try {
        const response = await api.post(`/user/${userId}/regen-energy`);
        set({ user: { ...get().user, energy: response.data.energy } });
      } catch (error) {
        showNotification('Ошибка при восстановлении энергии', 'error');
        set({ error: 'Ошибка при восстановлении энергии' });
      }
    },

    processClick: async (userId) => {
      try {
        const response = await api.post(`/user/${userId}/click`);
        set({
          user: {
            ...get().user,
            balance: response.data.balance,
            energy: response.data.energy,
            level: response.data.level,
          },
        });
      } catch (error) {
        showNotification('Ошибка при обработке клика', 'error');
        set({ error: 'Ошибка при обработке клика' });
      }
    },
  }))
);

export default useUserStore;