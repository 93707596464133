import React, { useEffect } from "react";
import axios from "axios-esm";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import BottomNavigation from "./components/BottomNavigation";
import Card from "./pages/Card";
import Tasks from "./pages/Tasks";
import Profile from "./pages/Profile";
import ConnectWallet from "./pages/ConnectWallet";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function WalletCallback() {
  const location = useLocation();

  useEffect(() => {
    const handleCallback = async () => {
      const queryParams = new URLSearchParams(location.search);
      const chatId = queryParams.get("chatId");
      const phantomEncryptionPublicKey = queryParams.get(
        "phantom_encryption_public_key"
      );
      const nonce = queryParams.get("nonce");
      const data = queryParams.get("data");

      if (!chatId || !phantomEncryptionPublicKey || !nonce || !data) {
        console.error("Некоторые параметры отсутствуют в URL.");
        return;
      }

      try {
        const response = await axios.get(
          "https://app.bdsm.money:5000/callback",
          {
            params: { chatId, phantom_encryption_public_key: phantomEncryptionPublicKey, nonce, data },
          }
        );

        console.log("Данные успешно отправлены на сервер:", response.data);
      } catch (error) {
        console.error("Ошибка при отправке данных на сервер:", error);
      }
    };

    handleCallback();
  }, [location]);

  return (
    <div>
      <h1>Подключение Phantom Wallet</h1>
      <p>Обрабатываем подключение кошелька...</p>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cards" element={<Card />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/connect-wallet" element={<ConnectWallet />} />
        <Route path="/callback" element={<WalletCallback />} />
      </Routes>
      <BottomNavigation />
      <ToastContainer position="top-right" autoClose={3000} />
    </BrowserRouter>
  );
}

export default App;
