import axios from 'axios-esm';

const BASE_URL = 'https://app.bdsm.money:5000';

export const api = axios.create({
  baseURL: BASE_URL,
});

// Логирование запросов
api.interceptors.request.use(
  (config) => {
    console.log('[Request]', config);
    return config;
  },
  (error) => {
    console.error('[Request Error]', error);
    return Promise.reject(error);
  }
);

// Логирование ответов
api.interceptors.response.use(
  (response) => {
    console.log('[Response]', response);
    return response;
  },
  (error) => {
    console.error('[Response Error]', error);
    return Promise.reject(error);
  }
);

export default api;
