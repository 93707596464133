import React, { useEffect, useState } from "react";
import axios from "axios-esm";
import WebApp from "@twa-dev/sdk";

function ProfileBlock() {
    const user = WebApp.initDataUnsafe?.user;
    const [userData, setUserData] = useState(null);
    const [walletAddress, setWalletAddress] = useState(null);
    const [isConnected, setIsConnected] = useState(false);

    const connectWallet = async () => {
        try {
            if (!window.solana) {
                alert("Phantom Wallet не установлен.");
                return;
            }

            console.log("Попытка подключения к Phantom Wallet...");
            const response = await window.solana.connect();
            const publicKey = response.publicKey.toString();
            setWalletAddress(publicKey);
            setIsConnected(true);
            console.log("Кошелёк подключён:", publicKey);
        } catch (error) {
            console.error("Ошибка подключения к кошельку:", error);
            alert("Не удалось подключиться к Phantom Wallet. Проверьте настройки.");
        }
    };

    const disconnectWallet = async () => {
        try {
            if (window.solana && window.solana.isConnected) {
                await window.solana.disconnect();
                setWalletAddress(null);
                setIsConnected(false);
                console.log("Кошелёк отключён.");
            }
        } catch (error) {
            console.error("Ошибка отключения кошелька:", error);
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(
                    `https://app.bdsm.money:5000/user/${user.id}`
                );
                setUserData(response.data);
                console.log("Данные пользователя загружены:", response.data);
            } catch (error) {
                console.error("Ошибка загрузки данных пользователя:", error);
            }
        };

        if (user && user.id) {
            fetchUserData();
        }
    }, [user]);

    return (
        <div className="ProfileBlock" style={{ padding: "20px", textAlign: "center" }}>
            <div className="avatar">
                <img
                    src="https://img.freepik.com/premium-vector/avatar-profile-icon_188544-4755.jpg?size=626&ext=jpg"
                    alt="Avatar"
                />
            </div>
            <div className="info">
                <h2>
                    {user?.first_name} {user?.last_name}
                </h2>
                <p>Уровень: {userData?.level}</p>
                <p>Баланс: {Math.floor(userData?.balance)} $FAP</p>
            </div>
            <div>
                {isConnected ? (
                    <div>
                        <p>Кошелёк: {walletAddress}</p>
                        <button onClick={disconnectWallet}>Отключить кошелёк</button>
                    </div>
                ) : (
                    <button onClick={connectWallet}>Подключить Phantom Wallet</button>
                )}
            </div>
        </div>
    );
}

export default ProfileBlock;
