import React, { useEffect, useState } from 'react';
import { BsCoin, BsArrowRight, BsCheck2, BsLightningChargeFill } from "react-icons/bs";
import WebApp from '@twa-dev/sdk';
import useUserStore from '../../store/UserStore';

function TapBlock() {
    const telegramId = WebApp.initDataUnsafe?.user?.id;
    const {
        user,
        fetchUser,
        processClick,
        regenEnergy,
    } = useUserStore();

    const [progressPercentage, setProgressPercentage] = useState(0);
    const [isTapped, setIsTapped] = useState(false);

    const levelRequirements = [0, 200, 1000, 10000];

    useEffect(() => {
        if (telegramId) {
            fetchUser(telegramId);
        }
    }, [telegramId, fetchUser]);

    useEffect(() => {
        if (user) {
            const currentLevelIndex = Math.max(0, user.level - 6);
            const nextLevelRequirement = levelRequirements[currentLevelIndex + 1] || Infinity;

            const progress = (user.balance / nextLevelRequirement) * 100;
            setProgressPercentage(Math.min(100, Math.max(0, progress)));
        }
    }, [user]);

    const handleTap = async () => {
        if (user.energy < 2) {
            alert('Недостаточно энергии для клика');
            return;
        }

        try {
            await processClick(telegramId);
            setIsTapped(true);
            setTimeout(() => setIsTapped(false), 150);
        } catch (error) {
            console.error('Ошибка при клике:', error);
        }
    };

    useEffect(() => {
        const energyInterval = setInterval(() => {
            if (telegramId) regenEnergy(telegramId);
        }, 5000);

        return () => clearInterval(energyInterval);
    }, [telegramId, regenEnergy]);

    if (!user) {
        return <div>Загрузка...</div>;
    }

    return (
        <div className='TapBlock'>
            <div className="TapBlock__container">
                <div className="valueMoney">
                    <div className="value">{Math.floor(user.balance)}</div>
                    <div className="icon">$FAP</div>
                </div>
                <div className="valueFarm">
                    {user.passiveIncomePerHour || 0} <span>$FAP</span> 1h farm
                </div>

                <div className="levelRange">
                    <div className="levelValue" style={{ width: `${progressPercentage}%` }}>
                        <span style={{ color: "white", position: "absolute", top: "-20px" }}>
                            {progressPercentage.toFixed(2)}%
                        </span>
                    </div>
                </div>

                <div className="textRange">
                    <div className="block">
                        <div className="icon"><BsCheck2 /></div>
                        <div className="text">{user.level} см.</div>
                    </div>
                    <div className="block">
                        <div className="text">{user.level + 1} см.</div>
                        <div className="icon"><BsArrowRight /></div>
                    </div>
                </div>
                <div className="tapBlock">
                    <div
                        className={`image ${isTapped ? 'tapped' : ''}`}
                        onClick={handleTap}
                    >
                        <img
                            src="https://i.postimg.cc/g2Vf9HY1/photo-2024-09-06-15-24-31.jpg"
                            alt=""
                        />
                    </div>
                </div>
                <div className="quanityTap">
                    <div className="icon"><BsLightningChargeFill /></div>
                    <div className="text">{Math.floor(user.energy)}/1500</div>
                </div>
            </div>
        </div>
    );
}

export default TapBlock;
