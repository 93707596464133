import React, { useEffect } from 'react';
import { BsCoin } from "react-icons/bs";
import WebApp from '@twa-dev/sdk';
import useUserStore from '../../store/UserStore';

function CardsHeader(props) {
    const telegramId = WebApp.initDataUnsafe?.user?.id;
    const { user, fetchUser } = useUserStore();

    useEffect(() => {
        if (telegramId) {
            fetchUser(telegramId); // Загружаем пользователя по его telegramId
        }
    }, [telegramId, fetchUser]);

    return (
        <div className="HomeHeader">
            <div className="HomeHeader__container">
                <div className="left">
                    {WebApp.initDataUnsafe?.user?.first_name} {WebApp.initDataUnsafe?.user?.last_name}
                </div>
                
                {props.showBalance && (
                    <div className="right">
                        <div className="right__block">
                            <div className="text">
                                {user ? Math.floor(user.balance || 0) : 'Загрузка...'}
                            </div>
                            <div className="icon">
                                <BsCoin />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CardsHeader;
